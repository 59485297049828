export default {
  primary: '#000000',
  secondary: '#FEDC29',
  textPrimary: '#000000',
  textSecondary: '#E5E5E5',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#F6F9FC',
  borderPrimary: '#000000',
  borderSecondary: '#E5E5E5',
  neutral: '#808080'
}
