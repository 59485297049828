import axios, { AxiosInstance } from 'axios'
import { stringify } from 'qs'
import BaseApi from '~/apis/BaseApi'

export default class AuthApi implements BaseApi {
  baseUrl: string
  axios: AxiosInstance
  constructor (baseUrl: string) {
    this.baseUrl = baseUrl
    this.axios = axios.create({
      baseURL: this.baseUrl,
      auth: {
        username: 'laoshi',
        password: '0d5afb133b74d5d0b433528740064cef'
      },
      timeout: 20000
    })
  }

  login (email: string, locale: string) {
    return this.axios.get(`${this.baseUrl}/v3/mail/register${stringify({
            email,
            redirectUrl: `${window.location.origin}/complete-sign-in`,
            platform: 'web',
            lang: (locale || 'en')
        }, { addQueryPrefix: true })}`)
  }
}
