import { AxiosError } from 'axios'
import { defineNuxtPlugin, NuxtApp } from '#app'
import LaoshiApi from '~/apis/laoshi.api'
import AuthApi from '~/apis/auth.api'
import AiApi from '~/apis/ai.api'
import AdminApi from '~/apis/admin.api'
import { getAuthToken } from '~/plugins/1.supabase'

export default defineNuxtPlugin((app) => {
  const config = useRuntimeConfig()

  const lang = useLocalStorage('i18next', 'en')
  const laoshiApi: LaoshiApi = new LaoshiApi(config.public.baseApiUrl, [lang.value], app as NuxtApp)
  const aiApi: AiApi = new AiApi(config.public.aiApiUrl, app as NuxtApp)
  const authApi: AuthApi = new AuthApi(config.public.baseAuthApiUrl)
  const adminApi: AdminApi = new AdminApi(config.public.baseAdminApiUrl, app as NuxtApp) as AdminApi
  laoshiApi.axios.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // return Promise.resolve(response.data)
    laoshiApi.retries = 0
    return response.data
  }, async (error: AxiosError) => {
    if (error.response?.status === 401 && !laoshiApi.retries) {
      laoshiApi.retries += 1

      const token = await getAuthToken(app.$auth)
      if (token) {
        const response = await laoshiApi.axios({
          ...error.config,
          headers: {
            ...error.config?.headers,
            Authorization: token
          }
        })
        return response.data
      }

      await app.$router.push('/sign-in')
    }

    if (error.response?.status !== 401) {
      app.$eventBus.emit('axios-error', error.response?.data?.message)
    }
    return Promise.reject(error)
  })
  const api = {
    laoshi: laoshiApi,
    auth: authApi,
    ai: aiApi,
    admin: adminApi
  } as const
  return {
    provide: {
      api
    }
  }
})
