<script setup lang="ts">

onMounted(() => {
  const themeStore = useThemeStore()
  themeStore.setTheme('base')
})

useHead({
  bodyAttrs: {
    class: 'bg-primary-background transition-colors'
  }
})
</script>
<template>
  <NuxtLayout>
    <NuxtLoadingIndicator :color="false" class="bg-secondary" />
    <NuxtPage />
  </NuxtLayout>
</template>
