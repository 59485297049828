import axios from 'axios'
import type { AxiosInstance } from 'axios'
import { NuxtApp } from '#app'
import BaseApi from '~/apis/BaseApi'
import { getAuthToken } from '~/plugins/1.supabase'

export default class AdminApi implements BaseApi {
  baseUrl: string
  axios: AxiosInstance
  retries: number

  constructor (baseUrl: string, vueApp: NuxtApp) {
    this.baseUrl = baseUrl
    this.axios = axios.create({
      baseURL: this.baseUrl,
      timeout: 20000
    })

    this.retries = 0

    this.axios.interceptors.request.use(async (config) => {
      const token = await getAuthToken(vueApp.$auth)
      if (token && config.headers) {
        config.headers.Authorization = token
      }

      return config
    }, (error) => {
      return Promise.reject(error)
    })
  }

  public parseTxt (text: string) {
    return this.axios.post(`${this.baseUrl}/v1/entities/parse/txt`, { text })
  }

  public checkAffiliate (userUid: string, affiliateSlug: string) {
    return this.axios.get(`${this.baseUrl}/v1/users/roles/${userUid}/check/${affiliateSlug}`)
  }

  public syncPositions (payload: {uid: string, parentId: number, children: number[]}) {
    return this.axios.post(`${this.baseUrl}/v1/entities/sync-positions`, payload)
  }

  public deleteEntity (uid: string, entityId: number) {
    return this.axios.delete(`${this.baseUrl}/v1/entities/${entityId}`)
  }

  public updateEntity (uid:string, payload: any) {
    return this.axios.patch(`${this.baseUrl}/v1/entities/${payload.id}`, payload)
  }

  public saveEntity (uid: string, payload: any, slug:string) {
    return this.axios.post(`${this.baseUrl}/v1/entities/${slug}`, { uid, ...payload })
  }

  public detachWord (uid: string, entityId: number, wordId: number) {
    return this.axios.delete(`${this.baseUrl}/v1/words/${wordId}/entities/${entityId}`)
  }

  public updateWord (uid: string, wordId: number, payload: any) {
    return this.axios.patch(`${this.baseUrl}/v1/words/${wordId}/`, payload)
  }
}
