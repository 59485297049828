<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import type { IButtonProps } from '~/types'

const emit = defineEmits(['click'])
const props = withDefaults(defineProps<IButtonProps>(), {
  size: 'md'
})

const computeColor = (color: string) => {
  // check if hex color
  if (color.match(/^#([0-9a-f]{3}){1,2}$/i)) {
    return `bg-[${color}]`
  } else {
    return `bg-${color}`
  }
}

// Size classes for different button types
const sizesRounded = {
  xs: 'p-1 text-xs',
  sm: 'p-1 text-sm',
  md: 'p-2 text-base',
  lg: 'p-3 text-lg'
}

const sizesTile = {
  xs: 'p-1 text-xs',
  sm: 'p-1 text-sm',
  md: 'px-8 py-[11.75px] text-base',
  lg: 'p-4 text-lg'
}

const sizesIcon = {
  xs: 'py-1 px-[7px] text-xs',
  sm: 'py-1 px-[7px] text-sm',
  md: 'p-[11px] text-base',
  lg: 'p-4 text-lg'
}
const sizeDefault = {
  xs: 'py-1 px-2 text-xs',
  sm: 'py-2 px-4 text-sm',
  md: 'py-[11.75px] px-8 text-base',
  lg: 'py-4 px-10 text-lg'
}

// Computed property for button size class
const computedSize = computed(() => {
  if (props.rounded) { return sizesRounded[props.size || 'md'] }
  if (props.icon) { return sizesIcon[props.size || 'md'] }
  if (props.tile) { return sizesTile[props.size || 'md'] }
  return sizeDefault[props.size || 'md']
})

// Computed properties for different button styles
const baseClasses = computed(() => {
  if (props.outlined) {
    return ['border-1', 'border-secondary-border', 'text-black', 'border-solid', 'hover:bg-gray-100']
  }
  if (props.color) {
    return [computeColor(props.color), 'hover:bg-opacity-90', 'active:bg-opacity-70']
  }
  if (props.inverted) {
    return ['text-black', 'hover:bg-opacity-20', 'active:bg-opacity-30']
  }

  return ['border-1', 'border-transparent', 'text-white', 'bg-black', 'hover:bg-opacity-90', 'active:bg-opacity-80']
})

const roundedClasses = computed(() => (props.rounded ? ['rounded-full'] : []))
const tileClasses = computed(() => (props.tile ? ['rounded-xl'] : []))
const defaultClasses = computed(() => (!props.rounded && !props.tile ? ['rounded-3xl'] : []))
const disabledClasses = computed(() => (props.disabled ? ['opacity-30', 'cursor-not-allowed'] : []))

// Computed property for button classes
const computedClasses = computed(() => [
  ...baseClasses.value,
  ...roundedClasses.value,
  ...tileClasses.value,
  ...defaultClasses.value,
  ...disabledClasses.value,
  computedSize.value
])

const computedComponent = computed(() => {
  return props.href ? 'a' : props.to ? resolveComponent('nuxt-link') : 'button'
})
</script>

<template>
  <component
    :is="computedComponent"
    ref="button"
    :href="href"
    :to="to"
    class="text-center text-sm font-sans transition-colors duration-300 flex items-center justify-center"
    :class="computedClasses"
    :disabled="disabled"
    @click="emit('click')"
  >
    <slot name="icon">
      <BaseIcon v-if="icon" :name="icon" />
    </slot>
    <slot />
  </component>
</template>
