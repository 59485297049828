import { createClient } from '@supabase/supabase-js'
import { SupabaseAuthClient } from '@supabase/supabase-js/dist/module/lib/SupabaseAuthClient'

export async function getAuthToken (auth: SupabaseAuthClient): Promise<string> {
  const { data, error } = await auth.getSession()
  if (error) {
    throw error
  }

  return data.session?.access_token || ''
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const client = createClient(
    config.public.supabase.url,
    config.public.supabase.key,
    {
      auth: {
        // handle session ourselves in useSupabaseAuth/completeMagicLinkSignIn
        detectSessionInUrl: false,
        autoRefreshToken: true
      }
    }
  )

  const auth = client.auth
  nuxtApp.vueApp.provide('auth', auth)
  nuxtApp.provide('auth', auth)
})
