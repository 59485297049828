export default {
  primary: '#000000',
  secondary: '#EBEFF2',
  textPrimary: '#000000',
  textSecondary: '#818A95',
  backgroundPrimary: '#CFD6DF',
  backgroundSecondary: '#F6F9FC',
  borderPrimary: '#000000',
  borderSecondary: '#DDE2E9'
}
