import axios, { type AxiosInstance } from 'axios'
import type { NuxtApp } from '#app'
import type { ClassTaskAuditionContent, ClassTaskSentenceContent, ClassTaskTestsContent } from '~/types'
import type BaseApi from '~/apis/BaseApi'
import type { ChatHistory, ChatMessage } from '~/types/chat'
import { getAuthToken } from '~/plugins/1.supabase'

export default class AiApi implements BaseApi {
  baseUrl: string
  axios: AxiosInstance
  constructor (baseUrl: string, vueApp: NuxtApp) {
    this.baseUrl = baseUrl
    this.axios = axios.create({
      baseURL: this.baseUrl,
      timeout: 90000
    })

    this.axios.interceptors.request.use(async (config) => {
      const token = await getAuthToken(vueApp.$auth)
      if (token && config.headers) {
        config.headers.Authorization = token
      }

      return config
    }, (error) => {
      return Promise.reject(error)
    })
  }

  public async generateTexts (data: {
    hsk?: string;
    grammarId?: number;
    vocabulary?: string;
    context?: string;
    splitWords: boolean;
  }) {
    const dataWithoutMinusOne = Object.keys(data).reduce((acc, key) => {
      const typedKey = key as keyof typeof data
      if (data[typedKey] !== -1) {
        (acc as any)[typedKey] = data[typedKey]
      }
      return acc
    }, {} as Partial<typeof data>)
    if (data.splitWords) {
      const res = await this.axios.post<ClassTaskTestsContent[]>(`${this.baseUrl}/langchain/texts`, dataWithoutMinusOne)
      return res.data
    } else {
      const res = await this.axios.post<ClassTaskSentenceContent[]>(`${this.baseUrl}/langchain/texts`, dataWithoutMinusOne)
      return res.data
    }
  }

  public async generateDialogue (data: any) {
    const res = await this.axios.post<ClassTaskAuditionContent>(`${this.baseUrl}/langchain/dialogue`, data)
    return res.data
  }

  public async generateChat (messages: ChatMessage[], locale: string) {
    const res = await this.axios.post<string>(`${this.baseUrl}/langchain/chat`, { messages, language: locale })
    return res.data
  }

  public async getChatHistory (uuid: string): Promise<ChatHistory> {
    const res = await this.axios.get<ChatHistory>(`${this.baseUrl}/chat/history/${uuid}`)
    return res.data
  }

  public async getUserChatHistory (uid: string): Promise<ChatHistory[]> {
    const res = await this.axios
      .get<ChatHistory[]>(`${this.baseUrl}/chat/history/user/${uid}?type=web_featured`)
    return res.data
  }

  public async postChatHistory (data: Pick<ChatHistory, 'id' | 'messages' | 'user_id' | 'type'>) {
    const res = await this.axios.post<ChatHistory>(`${this.baseUrl}/chat/history`, data)
    return res.data
  }

  public async updateChatHistory (data: Omit<ChatHistory, 'updated_at' | 'created_at'>) {
    const res = await this.axios.put<string>(`${this.baseUrl}/chat/history/${data.id}?user_id=${data.user_id}`, data)
    return res.data
  }

  public async deleteChatHistory (uuid: string) {
    const res = await this.axios.delete<string>(`${this.baseUrl}/chat/history/${uuid}`)
    return res.data
  }
}
