import { type Theme, themes } from '~/themes/index'

export interface ITheme {
    [key: string]: string;
}

export interface IThemes {
    [key: string]: ITheme;
}

export interface IMappedTheme {
    [key: string]: string | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
  return {
    '--color-primary': variables.primary || '',
    '--color-secondary': variables.secondary || '',
    '--color-text-primary': variables.textPrimary || '',
    '--text-secondary': variables.textSecondary || '',
    '--background-primary': variables.backgroundPrimary || '',
    '--background-secondary': variables.backgroundSecondary || '',
    '--border-primary': variables.borderPrimary || '',
    '--border-secondary': variables.borderSecondary || ''
  }
}

export const applyTheme = (theme: Theme): void => {
  const themeObject: IMappedTheme = mapTheme(themes[theme])
  if (!themeObject) { return }

  const root = document.documentElement

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return
    }

    root.style.setProperty(property, themeObject[property])
  })
}

export const extend = (
  extending: ITheme,
  newTheme: ITheme
): ITheme => {
  return { ...extending, ...newTheme }
}
