import { navigateTo } from '#app'
import type { RouteLocationNormalized } from 'vue-router'
import { getAuthToken } from '~/plugins/1.supabase'

export default defineNuxtRouteMiddleware(async (from, _to) => {
  const guestLinks = [
    'index',
    'sign-in',
    'complete-sign-in',
    'class-invite',
    'class-invitation-complete',
    /^\/library\/\d+$/,
    /^\/library\/\d+\/print\/?$/,
    /^\/users\/.+\/wordlists\/\d+$/,
    /^\/users\/.+\/wordlists\/\d+\/print\/?$/,
    /^\/users\/chat\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/share\/?$/,
    /^\/users\/chat\/?$/
  ]
  const isGuestPage = guestLinks.some((pattern) => {
    if (typeof pattern === 'string') {
      return from.name === pattern
    } else if (pattern instanceof RegExp) {
      return pattern.test(from.path || '')
    }
    return false
  })

  // Handle a special case for library route
  if (from.name === 'library') {
    const type = from.query.type
    if (type === 'list') {
      // Protected route
      return await handleAuth(from)
    }
    // other types are fine for guests
    return
  }

  if (!isGuestPage) {
    return await handleAuth(from)
  }

  async function handleAuth (from: RouteLocationNormalized) {
    const { $auth, ssrContext } = useNuxtApp()
    const token = await getAuthToken($auth)
    if (!token) {
      // user is not authenticated, or refresh failed
      // remember current location, and redirect back to it after sign in
      if (!ssrContext) {
        localStorage.setItem('redirect-to-after-sign-in', from.fullPath)
      }
      return navigateTo('/sign-in/')
    }
  }
})
