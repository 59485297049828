<script setup lang="ts">
const props = withDefaults(defineProps<{
  name: string,
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
  color?: string,
  filled?: boolean
}>(), {
  size: 'md',
  filled: true,
  color: undefined
})

const computedClasses = computed(() => {
  const sizeOrDefault = props.size || 'md'
  const classes: string[] = [sizeOrDefault]
  if (props.color) {
    classes.push('text-' + props.color)
  }
  return classes
})
</script>

<template>
  <Icon :name="`laoicon:${name}`" :class="computedClasses" :filled="filled" class="block" />
</template>

<style>
.sm {
  font-size: 16px;
}

.md {
  font-size: 24px;
}

.lg {
  font-size: 40px;
}

.xl {
  font-size: 52px;
}

.xxl{
  font-size: 76px;
  }

@media (min-width: 768px) {
  .xl {
    font-size: 64px;
  }
  .xxl {
    font-size: 106px;
  }
}

</style>
