/**
 * This plugin is used in mobile app webview to dispatch a custom event when the page loading ends so that the native
 * app can make its own loading indicator.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const event = new Event('indicator:loading:end')

  nuxtApp.hook('page:loading:end', () => {
    window.dispatchEvent(event)
  })
})
