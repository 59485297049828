import base from './default'
import type { IThemes } from './utils'
import learning from '~/themes/learning'

export const DEFAULT_THEME = 'base'

export const themes: IThemes = {
  base,
  learning
}

export type Theme = keyof typeof themes
