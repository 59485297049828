import { default as _404YSWxqHFCJ0Meta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/404.vue?macro=true";
import { default as class_45invitation_45completeUjeeCXo2nAMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/class-invitation-complete.vue?macro=true";
import { default as class_45invitedkq7pwehlpMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/class-invite.vue?macro=true";
import { default as index9HskXj1oQiMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/index.vue?macro=true";
import { default as _91entityId_93ABI2Xrdo8HMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/statistics/entity/[entityId].vue?macro=true";
import { default as _91tagId_93MM6h5dpMrYMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/statistics/tag/[tagId].vue?macro=true";
import { default as _91wordlistId_93VCZcsSO4MSMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/statistics/wordlist/[wordlistId].vue?macro=true";
import { default as indexoL6MQBhHXoMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/index.vue?macro=true";
import { default as complete_45sign_45inYxOBsh1TyaMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/complete-sign-in.vue?macro=true";
import { default as exercises11y0tSXDJtMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/exercises.vue?macro=true";
import { default as indexwff66ocfQUMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/index.vue?macro=true";
import { default as indexim6Zig0V2iMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/lessons/[id]/index.vue?macro=true";
import { default as viewWulN2sN7BPMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/lessons/[id]/view.vue?macro=true";
import { default as indexG4igThx15tMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/lessons/index.vue?macro=true";
import { default as indexAenlSThfWHMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/compare/[compareId]/index.vue?macro=true";
import { default as indexA3vFyBC6t8Meta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/index.vue?macro=true";
import { default as learnIevOzqkGbYMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/learn.vue?macro=true";
import { default as printVwXjO2CDVrMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/print.vue?macro=true";
import { default as indexmueCZ6xp7lMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/index.vue?macro=true";
import { default as _91id_93XDuZilwjZFMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/tags/[id].vue?macro=true";
import { default as _91id_930tS3qc5odhMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/[affiliateSlug]/[id].vue?macro=true";
import { default as importdSClvDrZgTMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/[affiliateSlug]/import.vue?macro=true";
import { default as indextxYhuV8xOWMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/[affiliateSlug]/index.vue?macro=true";
import { default as from_45textrJvguXEjdsMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/from-text.vue?macro=true";
import { default as sign_45inPaBIH952gAMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/sign-in.vue?macro=true";
import { default as indexa2Wyr9SPWiMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/[userUid]/wordlists/[wordListId]/index.vue?macro=true";
import { default as learnycYLMXLzseMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/[userUid]/wordlists/[wordListId]/learn.vue?macro=true";
import { default as printZnKxUgMkcMMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/[userUid]/wordlists/[wordListId]/print.vue?macro=true";
import { default as index60cDhcYcrIMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/chat/[uuid]/index.vue?macro=true";
import { default as shareuww6q6fV1yMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/chat/[uuid]/share.vue?macro=true";
import { default as index58KQRP00TSMeta } from "/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/chat/index.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/404.vue")
  },
  {
    name: "class-invitation-complete",
    path: "/class-invitation-complete",
    meta: class_45invitation_45completeUjeeCXo2nAMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/class-invitation-complete.vue")
  },
  {
    name: "class-invite",
    path: "/class-invite",
    meta: class_45invitedkq7pwehlpMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/class-invite.vue")
  },
  {
    name: "classrooms-id",
    path: "/classrooms/:id()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/index.vue")
  },
  {
    name: "classrooms-id-statistics-entity-entityId",
    path: "/classrooms/:id()/statistics/entity/:entityId()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/statistics/entity/[entityId].vue")
  },
  {
    name: "classrooms-id-statistics-tag-tagId",
    path: "/classrooms/:id()/statistics/tag/:tagId()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/statistics/tag/[tagId].vue")
  },
  {
    name: "classrooms-id-statistics-wordlist-wordlistId",
    path: "/classrooms/:id()/statistics/wordlist/:wordlistId()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/[id]/statistics/wordlist/[wordlistId].vue")
  },
  {
    name: "classrooms",
    path: "/classrooms",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/classrooms/index.vue")
  },
  {
    name: "complete-sign-in",
    path: "/complete-sign-in",
    meta: complete_45sign_45inYxOBsh1TyaMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/complete-sign-in.vue")
  },
  {
    name: "exercises",
    path: "/exercises",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/exercises.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexwff66ocfQUMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/index.vue")
  },
  {
    name: "lessons-id",
    path: "/lessons/:id()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/lessons/[id]/index.vue")
  },
  {
    name: "lessons-id-view",
    path: "/lessons/:id()/view",
    meta: viewWulN2sN7BPMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/lessons/[id]/view.vue")
  },
  {
    name: "lessons",
    path: "/lessons",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/lessons/index.vue")
  },
  {
    name: "library-id-compare-compareId",
    path: "/library/:id()/compare/:compareId()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/compare/[compareId]/index.vue")
  },
  {
    name: "library-id",
    path: "/library/:id()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/index.vue")
  },
  {
    name: "library-id-learn",
    path: "/library/:id()/learn",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/learn.vue")
  },
  {
    name: "library-id-print",
    path: "/library/:id()/print",
    meta: printVwXjO2CDVrMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/[id]/print.vue")
  },
  {
    name: "library",
    path: "/library",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/index.vue")
  },
  {
    name: "library-tags-id",
    path: "/library/tags/:id()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/tags/[id].vue")
  },
  {
    name: "library-wordlists-affiliateSlug-id",
    path: "/library/wordlists/:affiliateSlug()/:id()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/[affiliateSlug]/[id].vue")
  },
  {
    name: "library-wordlists-affiliateSlug-import",
    path: "/library/wordlists/:affiliateSlug()/import",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/[affiliateSlug]/import.vue")
  },
  {
    name: "library-wordlists-affiliateSlug",
    path: "/library/wordlists/:affiliateSlug()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/[affiliateSlug]/index.vue")
  },
  {
    name: "library-wordlists-from-text",
    path: "/library/wordlists/from-text",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/library/wordlists/from-text.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inPaBIH952gAMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/sign-in.vue")
  },
  {
    name: "users-userUid-wordlists-wordListId",
    path: "/users/:userUid()/wordlists/:wordListId()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/[userUid]/wordlists/[wordListId]/index.vue")
  },
  {
    name: "users-userUid-wordlists-wordListId-learn",
    path: "/users/:userUid()/wordlists/:wordListId()/learn",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/[userUid]/wordlists/[wordListId]/learn.vue")
  },
  {
    name: "users-userUid-wordlists-wordListId-print",
    path: "/users/:userUid()/wordlists/:wordListId()/print",
    meta: printZnKxUgMkcMMeta || {},
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/[userUid]/wordlists/[wordListId]/print.vue")
  },
  {
    name: "users-chat-uuid",
    path: "/users/chat/:uuid()",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/chat/[uuid]/index.vue")
  },
  {
    name: "users-chat-uuid-share",
    path: "/users/chat/:uuid()/share",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/chat/[uuid]/share.vue")
  },
  {
    name: "users-chat",
    path: "/users/chat",
    component: () => import("/builds/laoshi_io/web/teacher-space-web-frontend/pages/users/chat/index.vue")
  }
]