import axios, { LaoshiAxiosInstance } from 'axios'
import { stringify } from 'qs'
import { NuxtApp } from '#app'
import { getAuthToken } from '~/plugins/1.supabase'
import type {
  ClassTask,
  ClassTaskAudition,
  ClassTaskAuditionContent,
  ClassTaskSentenceContent,
  ClassTaskSentences,
  ClassTaskTestsContent,
  ContentGenerationType,
  CreateWordListResponse,
  IClassroom,
  ICommunityListItemProps,
  ICommunityTag,
  ICustomWord,
  IEntity,
  InvitationData,
  IPhrase,
  IWord,
  IWordList,
  IWordListBody,
  IWordListCreateForm,
  IWordStatistic,
  FocusedWordListsResponse,
  Lesson,
  LessonPayload,
  LessonTaskPositions,
  PaginatedData,
  StudentStatistics,
  TokenizeResponse,
  UserInfoResponse,
  CamelCasedPaginatedData,
  BranchLinkOptions,
  BranchUrlAnalyticParams,
  IExampleResponse,
  ITranslation,
  IExample
} from '~/types'
import BaseApi from '~/apis/BaseApi'

export default class LaoshiApi implements BaseApi {
  baseUrl: string
  axios: LaoshiAxiosInstance
  vueApp: NuxtApp
  retries: number

  constructor (baseUrl: string, lang: string[], vueApp: NuxtApp) {
    this.baseUrl = baseUrl
    this.vueApp = vueApp
    let preferencesFromLocalStorage = {}
    if (!vueApp.ssrContext) {
      preferencesFromLocalStorage = JSON.parse(localStorage?.getItem('preferences'))
    }
    let selectedLanguage = 'en'
    const langFromLocalStorage = preferencesFromLocalStorage?.uiLanguage
    if (langFromLocalStorage) {
      selectedLanguage = langFromLocalStorage
    }
    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': [selectedLanguage, ...lang].join(',')
      },
      //   withCredentials: true, // for response with Set-Cookies works
      timeout: 60000
    })
    this.retries = 0

    this.axios.interceptors.request.use(async (config) => {
      const token = await getAuthToken(vueApp.$auth)
      if (token && config.headers) {
        config.headers.Authorization = token
      }

      return config
    }, (error) => {
      // Do something with request error
      return Promise.reject(error)
    })
  }

  public changeLanguage (lang: string) {
    this.axios.defaults.headers['Accept-Language'] = lang
  }

  public getEntitiesIndex (type: string, page = 1) {
    return this.axios.get<PaginatedData<IEntity[]>>(`${this.baseUrl}/entities/root/?type=${type}&page=${page}`)
  }

  public getUserInfo (userId: string) {
    return this.axios.get<UserInfoResponse>(`${this.baseUrl}/users/${userId}/`)
  }

  public getUserByMail (email: string) {
    return this.axios.get<UserInfoResponse>(`${this.baseUrl}/users/email/${email}`)
  }

  public getEntityDetail (id: string) {
    return this.axios.get<IEntity>(`${this.baseUrl}/entities/${id}/`)
  }

  public searchEntities (query: Record<string, any>) {
    const queryString = stringify(query, { skipNulls: true, addQueryPrefix: true })
    return this.axios.get(`${this.baseUrl}/entities/${queryString}`)
  }

  public searchWords (query: Record<string, string>) {
    const queryString = stringify({ ...query, paginated: true }, { skipNulls: true, addQueryPrefix: true })
    return this.axios.get(`${this.baseUrl}/words/${queryString}`)
  }

  public getWord (id: string) {
    return this.axios.get<IWord>(`${this.baseUrl}/words/${id}`)
  }

  public searchWordsInWordLists (userUid: string, query: Record<string, string>) {
    const queryString = stringify({ ...query, paginated: true }, { skipNulls: true, addQueryPrefix: true })
    return this.axios.get(`${this.baseUrl}/users/${userUid}/wordlists/words/search${queryString}`)
  }

  public getWordListDetail (userUid: string, id: string) {
    return this.axios.get<IWordListBody>(`${this.baseUrl}/users/${userUid}/wordlists/${id}/`)
  }

  public getTagDetail (userUid: string, uuid: string) {
    return this.axios.get(`${this.baseUrl}/users/${userUid}/tags/${uuid}/`)
  }

  public getWordListsByUser (userId: string, qs: Record<string, string | number>) {
    return this.axios.get<CamelCasedPaginatedData<IWordList[]>>(`${this.baseUrl}/users/${userId}/wordlists/?${stringify(qs)}`)
  }

  public getTagsByUser (userId: string, qs: Record<string, string | number> = {}) {
    return this.axios.get<ICommunityTag[]>(`${this.baseUrl}/users/${userId}/tags/?${stringify(qs)}`)
  }

  public updateTag (userUid: string, tag: Partial<ICommunityListItemProps>) {
    return this.axios.patch(`${this.baseUrl}/users/${userUid}/tags/${tag.uid}/`, tag)
  }

  public createTag (userUid: string, tag: Partial<ICommunityTag>) {
    return this.axios.post(`${this.baseUrl}/users/${userUid}/tags/`, tag)
  }

  public deleteTag (userUid: string, tagUuid: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userUid}/tags/${tagUuid}/`)
  }

  public addWordToList (wordListId: string, word: string, userUid: string) {
    return this.axios.post(`${this.baseUrl}/users/${userUid}/wordlists/${wordListId}/words/${word}`)
  }

  public addCustomWordToList (wordListId: string, word: ICustomWord, userUid: string) {
    return this.axios.post(`${this.baseUrl}/users/${userUid}/wordlists/${wordListId}/words/`, word)
  }

  public updateUser (userUid:string, user: { name: string, email: string }) {
    return this.axios.patch(`${this.baseUrl}/users/${userUid}`, user)
  }

  public getPhrases (wordId: string) {
    return this.axios.get<IPhrase[]>(`${this.baseUrl}/phrases/${wordId}/`)
  }

  public suggestPhrase (data: { phrase: string, email: string, locale: string, wordId: string }) {
    return this.axios.post(`${this.baseUrl}/phrases/suggest/`, data)
  }

  public getClassrooms (userUid: string) {
    return this.axios.get<IClassroom[]>(`${this.baseUrl}/users/${userUid}/classrooms/`)
  }

  public createClassroom (classroom: IClassroom) {
    return this.axios.post<IClassroom>(`${this.baseUrl}/classrooms/`, classroom)
  }

  public updateClassroom (classroom: Partial<IClassroom>) {
    return this.axios.patch<IClassroom>(`${this.baseUrl}/classrooms/${classroom.id}/`, classroom)
  }

  public getClassroomDetail (classroomId: string) {
    return this.axios.get<IClassroom>(`${this.baseUrl}/classrooms/${classroomId}/`)
  }

  public addWordListsToClassroom (classroomId: string, wordListIds: string[], userId: string) {
    return this.axios.post(`${this.baseUrl}/users/${userId}/classrooms/${classroomId}/attach/wordlist`, { ids: wordListIds })
  }

  public addStudentsToClassroom (classroomId: string, studentIds: string[], userId: string) {
    return this.axios.post(`${this.baseUrl}/users/${userId}/classrooms/${classroomId}/attach/student`, { ids: studentIds })
  }

  public resendClassroomInvitation (classroomId: string, userEmail: string, userId: string) {
    return this.axios.post(`${this.baseUrl}/users/${userId}/classrooms/${classroomId}/resend/`, { email: userEmail })
  }

  public addTagsToClassroom (classroomId: string, tagIds: string[], userId: string) {
    return this.axios.post(`${this.baseUrl}/users/${userId}/classrooms/${classroomId}/attach/tag/`, { ids: tagIds })
  }

  public detachWordListFromClassroom (classroomId: string, wordListId: string, userId: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userId}/classrooms/${classroomId}/attach/wordlist/${wordListId}`)
  }

  public detachStudentFromClassroom (classroomId: string, studentId: string, userId: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userId}/classrooms/${classroomId}/attach/student/${studentId}`)
  }

  public detachTagFromClassroom (classroomId: string, tagId: string, userId: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userId}/classrooms/${classroomId}/attach/tag/${tagId}`)
  }

  public getStudentStatistics (studentId: string) {
    return this.axios.get<StudentStatistics>(`${this.baseUrl}/students/${studentId}/statistics/`)
  }

  public getStudentLists (classroomId: number, studentUid: string, query: any = {}) {
    return this.axios.get(`${this.baseUrl}/classrooms/${classroomId}/students/${studentUid}/wordlists/${stringify(query, {
            addQueryPrefix: true,
            skipNulls: true
        })}`)
  }

  public getListStatistic (classroomId: string, studentUid: string, listId: string, type: 'wordlist' | 'tag' | 'entity') {
    return this.axios.get<IWord[]>(`${this.baseUrl}/classrooms/${classroomId}/students/${studentUid}/${type}/${listId}/statistics/`)
  }

  public getClassroomStatistic (classroomId: number, wordListId: string, type: 'entity' | 'wordlist') {
    return this.axios.get(`${this.baseUrl}/classrooms/${classroomId}/${type}/${wordListId}/statistics/`)
  }

  public getWordAudio (id: string) {
    return this.axios.get(`${this.baseUrl}/words/synth/${id}`, { responseType: 'blob' })
  }

  public createWordList (userUid: string, wordList: IWordListCreateForm, words: string[] = [], customWords: ICustomWord[] = []) {
    const wordListData = {
      ...wordList,
      ownerUid: userUid,
      title: {
        custom: wordList.title
      },
      additionalWords: words,
      type: 'lesson'
    }
    return this.axios.post<CreateWordListResponse>(`${this.baseUrl}/users/${userUid}/wordlists/`, {
      wordlist: wordListData,
      customWords: customWords.map((w) => {
        // clone the word to avoid mutating the original
        const word = {
          ...w,
          translation: w.translations
        }
        delete word.translations
        return word
      })
    })
  }

  public updateWordList (userUid: string, wordList: Partial<IWordListCreateForm>) {
    const wordListData = {
      ...wordList,
      ownerUid: userUid,
      title: {
        custom: wordList.title
      },
      type: 'lesson'
    }
    return this.axios.patch(`${this.baseUrl}/users/${userUid}/wordlists/${wordList.id}/`, { wordlist: wordListData })
  }

  public attachWordListToTag (userUid: string, tagId: string, wordListIds: string[]) {
    return this.axios.post(`${this.baseUrl}/users/${userUid}/tags/${tagId}/wordlists/`, { wordListIds })
  }

  public updateWord (userUid: string, wordListId: string, word: ICustomWord) {
    return this.axios.patch(`${this.baseUrl}/users/${userUid}/wordlists/${wordListId}/words/${word.id}/`, word)
  }

  public detachWord (userUid: string, wordListId: string, wordId: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userUid}/wordlists/${wordListId}/words/${wordId}/`)
  }

  public detachWordListFromTag (userUid: string, tagId: string, wordListId: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userUid}/tags/${tagId}/wordlists/${wordListId}/`)
  }

  public deleteWordList (userId: string, wordListId: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userId}/wordlists/${wordListId}/`)
  }

  public getWordsFromWordList (userUid: string, wordListId: string) {
    return this.axios.get<IWord[]>(`${this.baseUrl}/words/wordlist/${wordListId}/user/${userUid}`)
  }

  public getWordListsByClassroom (id: number) {
    return this.axios.get(`${this.baseUrl}/classrooms/${id}/wordlists/`)
  }

  public getTagsByClassroom (id: number) {
    return this.axios.get(`${this.baseUrl}/classrooms/${id}/tags/`)
  }

  public deleteClassroom (id: number) {
    return this.axios.delete(`${this.baseUrl}/classrooms/${id}/`)
  }

  public detachEntityFromClassroom (id: any, uid: string, userId: string) {
    return this.axios.delete(`${this.baseUrl}/users/${userId}/classrooms/${id}/attach/entity/${uid}/`)
  }

  public attachEntitiesToClassroom (id: any, ids: string[], userId: string) {
    return this.axios.post(`${this.baseUrl}/users/${userId}/classrooms/${id}/attach/entity/`, { ids })
  }

  public getSubscribersCount (uid: string) {
    return this.axios.get(`${this.baseUrl}/users/${uid}/subscribers/count/`)
  }

  public getInvitationData (hash: string, email: string) {
    return this.axios.get<InvitationData>(`${this.baseUrl}/classrooms/invite/${hash}/?email=${email}`)
  }

  public invitationResponse (studentUid: any, token: string, response: boolean) {
    return this.axios.post(`${this.baseUrl}/classrooms/students/${studentUid}/accept`, { token, accepted: response })
  }

  public getGrammarIndex () {
    return this.axios.get(`${this.baseUrl}/grammars/names`)
  }

  public listenSSML (ssml:string) {
    return this.axios.post(`${this.baseUrl}/langchain/synthesize`, { ssml }, { responseType: 'blob' })
  }

  public createUser (data: any) {
    return this.axios.post<UserInfoResponse>(`${this.baseUrl}/users/`, data)
  }

  public getClassroomTasks (userUid: string, type: ContentGenerationType) {
    return this.axios.get<ClassTask[]>(`${this.baseUrl}/classroom-tasks`, { params: { userUid, type } })
  }

  public getPaginatedClassroomTasks (userUid: string, type?: ContentGenerationType, page?: number) {
    return this.axios.get<PaginatedData<ClassTask[]>>(`${this.baseUrl}/classroom-tasks`,
      { params: { userUid, type, page, paginate: true } })
  }

  public createClassroomTask (data: {
    userUid: string,
    title: string,
    type: ContentGenerationType,
    content: ClassTaskAuditionContent | ClassTaskSentenceContent | ClassTaskTestsContent,
    level?: string,
  }) {
    return this.axios.post(`${this.baseUrl}/classroom-tasks`, data)
  }

  public getClassroomTask (taskId: number) {
    return this.axios.get(`${this.baseUrl}/classroom-tasks/${taskId}`)
  }

  public updateClassroomTask (taskId: number, data: {
    type: ContentGenerationType,
    title?: string,
    task?: string,
    ownerUid?: string,
    content: ClassTaskAudition | ClassTaskSentences | ClassTaskTestsContent,
    level?: string,
  }) {
    return this.axios.put(`${this.baseUrl}/classroom-tasks/${taskId}`, data)
  }

  public deleteClassroomTask (taskId: number) {
    return this.axios.delete(`${this.baseUrl}/classroom-tasks/${taskId}`)
  }

  public tokenizeText (text: string) {
    return this.axios.post<TokenizeResponse>(`${this.baseUrl}/tokenize-with-words-from-database`, { text })
  }

  public createLesson (payload: LessonPayload) {
    return this.axios.post<{ message: string, data: Lesson }>(`${this.baseUrl}/lessons`, payload)
  }

  public getLessons () {
    return this.axios.get<Lesson[]>(`${this.baseUrl}/lessons`)
  }

  public getLesson (lessonId: string) {
    return this.axios.get<Lesson>(`${this.baseUrl}/lessons/${lessonId}`)
  }

  public updateLesson (lessonId: string, payload: LessonPayload) {
    return this.axios.put<{ message: string, data: Lesson }>(`${this.baseUrl}/lessons/${lessonId}`, payload)
  }

  public deleteLesson (lessonId: number) {
    return this.axios.delete<{ message: string }>(`${this.baseUrl}/lessons/${lessonId}`)
  }

  public syncLessonTasks (payload: { lessonId: number, taskPositions: LessonTaskPositions }) {
    return this.axios.put<{ message: string }>(`${this.baseUrl}/lessons/${payload.lessonId}/tasks/sync`, payload)
  }

  public getWordStatistic (userUid: string, wordIds: string[]) {
    return this.axios.get<IWordStatistic[]>(`/users/${userUid}/word/statistic`, { params: { wordIds } })
  }

  public updateWordStatistic (userUid: string, words: IWordStatistic[]) {
    return this.axios.post(`/users/${userUid}/word/statistic`, { words })
  }

  public getFocusedWordLists (userUid: string) {
    return this.axios.get<FocusedWordListsResponse>(`${this.baseUrl}/users/${userUid}/wordlists/focused`)
  }

  public getFocusedEntities (userUid: string) {
    return this.axios.get(`${this.baseUrl}/users/${userUid}/entities/focused`)
  }

  public getFocusedStats (userUid: string, type: string, wordListId: string) {
    return this.axios.get(`${this.baseUrl}/users/${userUid}/wordlists/focused/${type}/${wordListId}/stats`)
  }

  public postAiFeedbackMessage (payload: { message: string, stars: number, payload: object, email: string, context: object}) {
    return this.axios.post(`${this.baseUrl}/slack/ai-feedback`, payload)
  }

  public getWordTranscription (word: string) {
    return this.axios.get<string>(`${this.baseUrl}/words/${word}/transcription`)
  }

  public getBranchLink (data: BranchLinkOptions, analytics: BranchUrlAnalyticParams) {
    return this.axios.post<{url: string}>('/branch/create', { data, analytics })
  }

  public getExamples (wordId: string) {
    return this.axios.get<IExampleResponse[]>(`${this.baseUrl}/words/${wordId}/examples`)
  }

  public deleteExample (entityId: number, id: number) {
    return this.axios.delete(`${this.baseUrl}/entity/${entityId}/examples/${id}`)
  }

  public updateExample (entityId: number, id: number, data: IExample) {
    return this.axios.patch(`${this.baseUrl}/entity/${entityId}/examples/${id}`, data)
  }
}
