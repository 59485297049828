<template>
  <div class="p-12 flex justify-center flex-col align-center items-center">
    <div>
      {{ $t('anErrorHasOccurred') }}
      <code>{{ error }}
      </code>
    </div>
    <img class="w-[400px] py-4" src="/cup.svg">
    <BaseButton class="w-[200px]" @click="handleError">
      {{ $t('home') }}
    </BaseButton>
  </div>
</template>
<script setup>

const props = defineProps({
  error: Object
})
const handleError = () => clearError({ redirect: '/' })
</script>
