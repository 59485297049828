import { applyTheme } from '~/themes/utils'
import type { Theme } from '~/themes'

/**
 * Store for managing the application's theme.
 * Handles the application of different themes and stores the current theme.
 */
export const useThemeStore = defineStore('theme', {
  /**
   * State: Holds the current theme of the application.
   * Initially set to 'base', and is of type `Theme`.
   */
  state: () => ({
    theme: 'base' as Theme
  }),

  /**
   * Action to set and apply a new theme.
   * @param theme - The new theme to be applied, of type `Theme`.
   * Invokes `applyTheme` to apply the specified theme and updates the state.
   */
  actions: {
    setTheme (theme: Theme) {
      applyTheme(theme)
      this.theme = theme
    }
  }
})
